import React from 'react';

function Footer() {
  return (
    <footer className="text-xs text-gray-500 py-4">
      <p className='text-center'><span className="font-semibold"> Aleena Alby</span> © 2024 Made with React. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
