import React, { useState, useEffect } from "react";
import coverImage from "../assets/coverimg1.jpg";
import screenshot1 from "../assets/fc1.png";
import screenshot2 from "../assets/fc2.png";
import { Heart } from 'lucide-react';


const FacebookPhotoCounter = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loves, setLoves] = useState(0);

  const postId = "10306753257183054" 
  const API_BASE_URL = "https://post-interactions.aleenaalby20.workers.dev"
  
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${API_BASE_URL}/get?postId=${postId}`);
      const data = await res.json();
      console.log(data);
      setComments(data.comments);
      setLoves(data.loves);
  }
  fetchData()
  }, [postId, API_BASE_URL])


  const handleCommentSubmit = async () =>{
    if (newComment.trim()) {
      const response = await fetch(`${API_BASE_URL}/add-comment?postId=${postId}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ newComment })
      });
      const newCommentObject = await response.json();
      setComments([...comments, newCommentObject]);
      setNewComment('');
    }
  }

  const handleLoveClick = async () => {
    const response = await fetch(`${API_BASE_URL}/add-love?postId=${postId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    setLoves(data.loves);
  };
  return (
    <div className="min-h-screen text-gray-900 p-8">
       <header className="relative w-full h-64">
        {/* Cover Picture */}
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${coverImage})`,
          }}
        ></div>
         <div className="relative z-10 flex items-center justify-center h-full bg-black bg-opacity-50">
          <div className="text-center text-white">
            <h1 className="text-xl font-bold">Facebook Photo Counter</h1>
            <p className="text-xs mt-2">By Aleena Alby | Nov 1, 2024 | <a href="/" className="font-semibold underline">Home</a></p>
          </div>
        </div>
        {/* <h1 className="text-4xl font-bold text-red-500">Facebook Photo Counter</h1>
        <p className="text-sm text-gray-500 mt-2">By Aleena Alby | Nov 1, 2024</p> */}
      </header>

      {/* Introduction Section */}
      <section className="mt-8 max-w-4xl mx-auto">
        <p className="text-xs leading-relaxed">
          This project started out of pure curiosity.
          I’ve always loved uploading photos to Facebook and wanted to explore what kind of insights
          I could gather about my own activity. This curiosity led me to delve into the world of the
          <span className="font-semibold"> Meta Developer Platform </span>
          to understand how the Facebook API works.
        </p>
        <p className="text-xs leading-relaxed mt-4">
          Beyond just creating a tool to count my Facebook photos,
          this project gave me valuable insights into <span className="font-semibold"> privacy concerns </span>,
          <span className="font-semibold"> data security </span>, and the inner workings of an API at scale.
        </p>

        {/* Facebook Photo Counter Link */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold text-gray-900">Try It Yourself</h2>
          <p className="mt-4 text-xs leading-relaxed">
            You can try the Facebook Photo Counter tool by visiting the link below:
          </p>
          <a
            href="https://facebook-photo-counter.onrender.com/auth/facebook"
            target="_blank"
            rel="noopener noreferrer"
            className="text-red-500 hover:underline text-xs font-medium"
          >
            Facebook Photo Counter Tool
          </a>
        </div>

         {/* Image 1 */}
        <div className="mt-8">
          <img
            src={screenshot1}
            alt="Result 1"
            className="w-full max-w-4xl rounded-lg shadow-lg"
          />
          <p className="mt-2 text-gray-500 text-center text-xs">Facebook Login Screen: Granting permissions to AlbumInsights for accessing photo-related data securely via the Facebook API</p>
        </div>

        {/* Image 2 */}
        <div className="mt-8">
          <img
            src={screenshot2}
            alt="Result 2"
            className="w-full max-w-4xl rounded-lg shadow-lg"
          />
          <p className="mt-2 text-gray-500 text-center text-xs">JSON Response</p>
        </div>
      </section>

      {/* Love Button Section */}
      <section className="max-w-4xl mx-auto mt-8 flex items-center justify-center space-x-4">
        <button
          onClick={handleLoveClick}
          className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-red-50 transition-colors focus:outline-none text-xs"
        >
          <Heart
            className={`w-6 h-6 ${loves > 0 ? 'text-red-500 fill-current' : 'text-gray-500'}`}
          />
          <span>{loves}</span>
        </button>
      </section>

      <section className="max-w-4xl mx-auto mt-8 bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">Leave an Anonymous Comment</h2>
      <textarea
          placeholder="Share your thoughts anonymously..."
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="w-full mb-4 p-3 border border-gray-300 rounded-lg resize-none focus:ring-2 focus:ring-blue-200 text-xs"
          rows={4}
        />
      <button
      onClick={handleCommentSubmit}
        className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-400 text-xs"
        >
          Submit Comment
        </button>

         {/* Comments Display */}
         {comments.length > 0 && (
          <div className="mt-6">
            <h3 className="text-xl font-medium mb-4 text-gray-700">Recent Comments</h3>
            {comments.map((comment) => (
              <div
                key={comment.id}
                className="bg-gray-50 p-4 rounded-lg mb-3 border border-gray-200"
              >
                <p className="text-gray-800 text-xs">{comment.text}</p>
                <p className="text-xs text-gray-500 mt-2 text-xs">
                  Anonymous • {comment.timestamp}
                </p>
              </div>
            ))}
          </div>
        )}
      </section>




    </div>
  );
};

export default FacebookPhotoCounter;
