import React from 'react';
import coverImage from "../assets/𝑉𝑖𝑛𝑐𝑒𝑛𝑡_𝑉𝑎𝑛_𝐺𝑜𝑔ℎ_cover.jpeg"; 
// import img1 from "../assets/vat1.jpg";
// import img2 from "../assets/vat2.jpg";
// import img3 from "../assets/vat3.jpg";
import img4 from "../assets/van1.jpg";
import img5 from "../assets/van2.jpg";

const RomeTravelBlog = () => {
  return (
    <div className="min-h-screen  text-gray-900 p-8">
      <header className="relative w-full h-64">
        <div 
          className="absolute inset-0 bg-cover bg-center "
          style={{
            backgroundImage: `url(${coverImage})`,
            filter: 'brightness(0.6)',
          }}
        ></div>
        <div className="relative z-10 flex items-center justify-center h-full">
          <div className="text-center text-white">
            <h1 className="text-xl font-bold">The Art of Seeing</h1>
            <p className="text-sm mt-2">Aleena | Feburary 4, 2025 | <a href="/" className="font-semibold underline">Home</a></p>
          </div>
        </div>
      </header>

      <section className="mt-8 max-w-4xl mx-auto">
        <p className="text-xs leading-relaxed">
        I never consider myself a “writer”, let alone a blogger. 
        But lately, a quiet voice inside my heart nudging me to put my thoughts to words. 
        It’s strange, really. I’ve always admired people who could effortlessly express themselves, 
        whether through writing or speaking—but it’s never been “my thing.” Yet, here I am, giving it a try.
        </p>
        <p className="text-xs leading-relaxed mt-4">
        Italy awakened something in me. The colours, the textures, 
        the stories behind every brushstroke. It all left me in awe. When I returned home, I wanted more.
        So, I decided to make visiting the National Gallery in London a highlight for January. 
        There’s something about London’s messy charm, its blend of grit and grandeur, that pulls me in. 
        It’s a city that feels alive, constantly moving, constantly evolving. I love its chaotic, fast-paced energy, 
        but there’s no denying it’s also undeniably expensive. That’s the part that usually makes me think twice before 
        stepping into the city. 
        </p>
        <p className="text-xs leading-relaxed mt-4">
        I wasn’t expecting such a long queue, especially in the rain. 
        Compared to Kochi, the UK rain is almost polite. 
        </p>
        <p className="text-xs leading-relaxed mt-4">
        It had already been a long day for me, and I hadn’t slept well the night before. 
        I was determined to make the most of my visit to the National Gallery. 
        I prepared a little note of what I wanted to see inside. My eyes were on Vincent van Gogh’s Sunflowers. 
        I headed straight for Room 31, where the Sunflowers was displayed. Part of me wanted to take my time, 
        to wander calmly through the gallery, soaking in every painting, every detail. But that day, 
        I was in a bit of a hurry. 
        </p>
        {/* <p className="text-xs leading-relaxed mt-4">
        As you enter the Basilica, you’ll be amazed by the beauty in every direction. One of the first things you'll see is <span className="italic">Michelangelo’s Pietà</span>, 
        a stunning sculpture of Mary holding the body of Christ. It’s placed in the first chapel on the right, and the detail makes it look so lifelike.
        You’ll also notice <span className="italic">St. Peter's Baldacchino</span>, a huge bronze canopy above the Papal Altar. 
        Made by Bernini, this 29-meter tall structure is a beautiful piece of art that connects heaven and earth. 
        Around the base of the dome’s drum, the solemn words of Matthew’s Gospel encircle 
        the space: <span className="font-semibold italic">“Tu es Petrus et super hanc petram aedificabo ecclesiam mean et tibi dabo claves regni caelorum” ("You are Peter, and on this rock I will build my church, and to you, 
          I will give the keys of the kingdom of heaven" - Mt 16:18).</span> 
        </p> */}
        <div className="mt-8 grid md:grid-cols-2 gap-4">
          <img 
            src={img4}
            alt="On a train in Rome with luggage" 
            className="rounded-lg shadow-md w-full h-[400px] object-cover"
          />
          <img 
            src={img5}
            alt="Vatican View" 
            className="rounded-lg shadow-md w-full h-[400px] object-cover"
          />
        </div>
        <p className="text-xs leading-relaxed mt-4">WIP....</p>
        {/* <p className="text-xs leading-relaxed mt-4">
        For the Catholic Church, the Chair of St. Peter is a very sacred relic. According to some stories, 
        St. Peter used to sit on this very chair during his sermons. Although archaeologists differ on the 
        authenticity of the chair, it has been considered an important relic since the first century, after 
        the death of St. Peter.
        </p>
        <p className="text-xs leading-relaxed mt-4">
        The dedication and hard work of countless individuals throughout history are etched into every corner of this space. 
        Every direction I turned offered something extraordinary to behold. I realized how much I didn’t know about the rich history 
        and stories behind the many things I was seeing. But even that feeling was fleeting as I balanced 
        capturing memories with my camera and simply enjoying the privilege of being there.
        </p>
        <p className="text-xs leading-relaxed mt-4">
        It is hard to truly express in words the magnitude of what you see and experience in this place. 
        The morning I spent in the Vatican was unlike anything I have seen or experienced in almost twenty-six years of my life. 
        And I know that if I returned to Rome– I would do it all again, exactly the same way I did.
        </p>
        <p className="text-xs leading-relaxed mt-4">
        Rome is truly worth the effort. As a movie enthusiast, Rome looked exactly how I had imagined it from countless films. 
        Italy has always been at the top of my bucket list. 
        </p> */}

        {/* Key Learnings Section */}
        {/* <div className="mt-8">          
          <div className="space-y-6">
            <div className="text-xs leading-5">
              <div className="flex items-center mb-3">
                <h3 className="text-sm font-semibold"><span className="text-red-500">#</span> 1. Book Tickets Early</h3>
              </div>
              Book tickets as early as possible. It was disheartening to find that tickets for the Vatican museum were sold out well into December. 
              To avoid falling prey to fake tickets, I made sure to buy from official websites. 
              While sites like Expedia and booking.com offer tickets with guided tours, they can be more expensive. 
              Refrain from buying tickets from street vendors. 
              These tickets are often fake or means of distractions, leading to unnecessary troubles. 
              Even the skip-the-line tickets could turn out to be counterfeit, so it is best to stick with official sources and pre-booked tickets to avoid hassles and save time. 
  
            </div>
            <div className="mt-8">
              <img 
                src={img1}
                alt="Rome Streetscape" 
                className="rounded-lg shadow-md w-full md:w-[1200px] h-[400px] object-cover"
              />
            </div>

            <div className="text-xs leading-5">
              <div className="flex items-center mb-3 ">
                <h3 className="text-sm font-semibold"><span className="text-red-500">#</span> 2. Getting around</h3>
              </div>
              <p className="text-xs leading-relaxed">
              One of the most important tips for travellers in Rome are to avoid driving inside major cities. 
              The streets can be chaotic, congested, and filled with pedestrians, making it a stressful and potentially dangerous experience. 
              Instead, opting for public transportation is the best way to get around. Buses, trains, the metro, and rail cars all run on the same transit passes, 
              making it easy and convenient to explore the city. Tickets can be purchased at the stations as well. However, 
              if you're a bit paranoid like I am, it's well worth it to get the multi-day transit passes, if only for peace of mind. They can be bought at any "tabacchi" (tobacco store), and fortunately, they are almost every 
              few hundred meters in the city. Some stations might be hard to find in the list using English, so make sure you know the city's name in Italian (Firenze for Florence, Napoli for Naples, etc.). It took me a few days to figure this out, but once I did, it was easy-peasy!
              </p>
              <p className="text-xs leading-relaxed mt-4">
              For a more unconventional mode of transportation, 
              I highly recommend trying an e-scooter. I personally used the Dott e-scooter during my travels in Rome, 
              and it was a fun and convenient way to get around the city. I simply signed in using my Google account and paired it with PayPal, 
              and I was ready to go. I was able to explore the city at my own pace.
              </p>
              <p className="text-xs leading-relaxed mt-4">
                While the experience of riding an e-scooter in Rome may be daunting at first, 
                especially for those not accustomed to European road rules, it is well worth the effort. 
                The Polizia are generally supportive and understanding of foreign visitors, so you need not worry 
                about fines or penalties. Just be sure to pay attention to the roads, as some paths are bumpy or 
                made of brick, which can make for a less smooth ride.
                </p>
            </div>

            <div className="text-xs leading-5">
              <div className="flex items-center mb-3">
                <h3 className="text-sm font-semibold">
                    <span className="text-red-500">#</span> 3. Language
                </h3>
              </div>
              <p>
              I didn't have too many problems. I do NOT speak Italian. 
              Even the basics help (scuzie (excuse me), Ciao (hello goodbye), and numbers (1-10) made things very easy. 
              Addresses can be confusing (via/villa, etc) and are extremely important for directions, but just showing the address on Google Maps makes things way easier. 
              I even needed to get a few things from the store, and just using Google Translate for very specific things is VERY useful. Just showing a little effort makes a huge difference. 
              </p>
            </div>
            <div className="text-xs leading-5">
              <div className="flex items-center mb-3">
                <h3 className="text-sm font-semibold">
                    <span className="text-red-500">#</span> 4. Safety 
                </h3>
              </div>
              <p>
              I felt safe. There are military, police, security, rail police, and security for specific 
              tourist spots all over the place. However, extra caution is needed at train stations. 
              Keep your belongings close, preferably on your lap, and store items deep inside your backpack. 
              Stay alert and mindful of your surroundings.
              </p>
            </div>
          </div>    
        </div> */}

        {/* Placeholder Images */}
        {/* <div className="mt-8 grid md:grid-cols-2 gap-4">
          <img 
            src={img2}
            alt="On a train in Rome with luggage" 
            className="rounded-lg shadow-md w-full md:w-[1200px] h-[400px] object-cover"
          />
          <img 
            src={img3}
            alt="Vatican View" 
            className="rounded-lg shadow-md w-full md:w-[1200px] h-[400px] object-cover"
          />
        </div> */}

        {/* Closing Thoughts */}
        {/* <div className="mt-8">
            <p className="text-xs leading-relaxed ">
                Rome, the risk is much higher than I've seen in London.
            </p>
            <p className="text-xs leading-relaxed mt-4">
                All of that being said, I felt safe as long as you pay attention to your surroundings and things. 
                it’s a journey I’ll always remember. Of course, I would come back again—to see the things I haven’t seen yet!
            </p>
            <p className="text-xs leading-relaxed mt-4">
                For anyone who is on a similar journey, my advice would be to embrace the chaos and delve into the unknown. 
                I hope this helps people. 
            </p>
          <p className="mt-4 italic text-red-500 text-sm">Happy travels!</p>
        </div> */}
      </section>
    </div>
  );
};

export default RomeTravelBlog;